.donationRow {
  color: rgb(50, 50, 50);
}

.donationRowInvalid {
  border: 2px solid red;
}

.donationRow > td {
  padding: 5px;
  max-width: 200px;
  font-size: 13px;
}

.donationRow:nth-child(2n+1) {
  background-color: rgb(240, 240, 240);
}

.invalidField {
  color: red;
}

.textButton {
  cursor: pointer;
  margin: 0 4px;
}

.textButton:hover {
  color: lightgreen;
}

.doneIcon {
  color: green;
}

.invalidAddress {
  color: red;
}
