.donationTableContainer {
  text-align: center;
}

.donationTable {
  display: inline-block;
  border-collapse: collapse;
}

.donationTable > thead > tr > th {
  font-size: 13px;
}

.donationTable > thead > th {
  color: rgb(50, 50, 50);
  padding: 10px;
}

.datePickerLabel {
  font-weight: bold;
  font-size: 20px;
}

.datePicker {
  height: 20px;
  font-size: 20px;
  margin: 15px;
  margin-right: 45px;
}

.donationsRetrieveButton {
  height: 28px;
  font-size: 20px;
}